import React from 'react';
import { Router } from '@reach/router';

import Page from '~layout/Page';

import VerifyEmail from '~containers/verify-email/VerifyEmail';

const Verify = () => (
  <Page>
    <Router>
      <VerifyEmail path="/verify/:username/:token" />
    </Router>
  </Page>
);

export default Verify;
