import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import AccessLevelLabel from '~components/access-level-label/AccessLevelLabel';

const VerifyEmailPaper = ({ username, access_level, is_admin }) => {
  const theme = useTheme();
  const isSmallerThanXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Typography variant="h4" component="h1" align="center" gutterBottom>
        Thanks for verifying your account at Aleph Beta!
      </Typography>

      <Typography variant="h5" align="center" gutterBottom>
        Please take time to set up your profile.
      </Typography>

      <Box my={2} p={2} borderRadius={4} bgcolor="#dfe6e9">
        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item>
            <Typography
              variant={isSmallerThanXs ? 'subtitle2' : 'h6'}
              component="p"
              align="center"
              style={{ fontWeight: 900 }}
            >
              {username}
            </Typography>
          </Grid>
          <Grid item>
            <AccessLevelLabel access_level={access_level} is_admin={is_admin} />
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={4}>
          <Grid item>
            <Button component={GatsbyLink} to="/" variant="contained" color="primary">
              Explore Aleph Beta
            </Button>
          </Grid>

          <Grid item>
            <Button component={GatsbyLink} to="/account" variant="contained" color="secondary">
              Set up profile
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

VerifyEmailPaper.propTypes = {
  username: PropTypes.string.isRequired,
  access_level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  is_admin: PropTypes.bool
};

VerifyEmailPaper.defaultProps = {
  access_level: null,
  is_admin: false
};

export default VerifyEmailPaper;
